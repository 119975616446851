<template>
  <div class="corporation-card-testimonial">
    <div class="corporation-card-testimonial__img">
      <img
        :src="`/images${img}`"
        alt="startup.png"
      >
    </div>
    <div class="corporation-card-testimonial__desc">
      <p> {{ desc }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardTestimonial",
  props: {
    img: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
      default: "-",
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/style";
@import "src/assets/css/calculate";

$media-query-sm: 699;

@mixin small() {
  @media (max-width: $media-query-sm + 'px') {
    @content;
  }
}

@mixin big() {
  @media (min-width: $media-query-sm + 1 + 'px') {
    @content;
  }
}

.corporation-card-testimonial {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  justify-content: center;
  width: 19.5em;
  min-height: 18em;

  &__img {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 5rem;

    img {
      object-fit: cover;
    }
  }

  &__desc {
    margin-top: 1.5em;
    margin-bottom: -1em;
    color: $color-base-text;
    text-align: center;
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    padding: 0 12px 0 12px;
  }

  @include small() {
    width: 20em;
    min-height: 15em;
    height: 20vw;

    &__img {
      margin-top: .2em;
    }

    &__desc {
      margin-top: 1em;
    }
  }
}
</style>
