import InternalAPI from '@/app/baseAxios';
import '../types';

/** @borrows InternalAPI */

export default class FormCorporateRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpont = '/v1/business';
  }

  /**
     * @param payload {object}
     * @param status {string}
     * @return {Promise<{data: formCorporate[], err: Error}}
     */

  async create(payload) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.post(`${this.endpont}`, payload);
      if (res.data && res.status === 200) {
        return { data: res.data, err: null };
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response) };
    }
  }
}
