/** @type {Array<Content>} */
const CATEGORY = [
  {
    label: 'Ad Tech',
    value: 'Ad Tech',
  },
  {
    label: 'Advertisement',
    value: 'Advertisement',
  },
  {
    label: 'Agriculture',
    value: 'Agriculture',
  },
  {
    label: 'Analytics',
    value: 'Analytics',
  },
  {
    label: 'Artificial Intelligence',
    value: 'Artificial Intelligence',
  },
  {
    label: 'Big Data',
    value: 'Big Data',
  },
  {
    label: 'Bio-Technology',
    value: 'Bio-Technology',
  },
  {
    label: 'Clean Tech',
    value: 'Clean Tech',
  },
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'Developer Tools',
    value: 'Developer Tools',
  },
  {
    label: 'Ecommerce',
    value: 'Ecommerce',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Enterprice Solution',
    value: 'Enterprice Solution',
  },
  {
    label: 'Financial Tech',
    value: 'Financial Tech',
  },
  {
    label: 'Food Tech',
    value: 'Food Tech',
  },
  {
    label: 'Gaming',
    value: 'Gaming',
  },
  {
    label: 'General Internet',
    value: 'General Internet',
  },
  {
    label: 'General Software',
    value: 'General Software',
  },
  {
    label: 'Hardware',
    value: 'Hardware',
  },
  {
    label: 'Health',
    value: 'Health',
  },
  {
    label: 'Heavy Industry',
    value: 'Heavy Industry',
  },
  {
    label: 'Internet Infrastructure',
    value: 'Internet Infrastructure',
  },
  {
    label: 'Investments',
    value: 'Investments',
  },
  {
    label: 'Lifestyle',
    value: 'Lifestyle',
  },
  {
    label: 'Logistics & Transportation',
    value: 'Logistics & Transportation',
  },
  {
    label: 'Marketplace & Platforms',
    value: 'Marketplace & Platforms',
  },
  {
    label: 'Media',
    value: 'Media',
  },
  {
    label: 'Music & Entertainment',
    value: 'Music & Entertainment',
  },
  {
    label: 'Navigation',
    value: 'Navigation',
  },
  {
    label: 'Outsourcing',
    value: 'Outsourcing',
  },
  {
    label: 'Professional Services',
    value: 'Professional Services',
  },
  {
    label: 'Publishing',
    value: 'Publishing',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Recognition Tech',
    value: 'Recognition Tech',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Science',
    value: 'Science',
  },
  {
    label: 'Search & Discovery',
    value: 'Search & Discovery',
  },
  {
    label: 'Security',
    value: 'Security',
  },
  {
    label: 'Sharing Economy',
    value: 'Sharing Economy',
  },
  {
    label: 'Social Networking & Communication',
    value: 'Social Networking & Communication',
  },
  {
    label: 'Sofware as a Service',
    value: 'Sofware as a Service',
  },
  {
    label: 'Space',
    value: 'Space',
  },
  {
    label: 'Travel',
    value: 'Travel',
  },
  {
    label: 'Video',
    value: 'Video',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

module.exports = { CATEGORY };
