const DATA = [
  {
    title: 'Diskon Korporasi Khusus',
    desc: 'Biaya pengiriman yang terjangkau dan harga yang bersaing dengan kompetitor.',
    img: 'corporation/discount.svg',
    bgColor: '',
  },
  {
    title: 'Special Handling',
    desc: 'Memberikan pelayanan yang fleksibel sesuai kebutuhan. Termasuk pada waktu Pick Up.',
    img: 'corporation/pickup.svg',
    bgColor: '',
  },
  {
    title: 'Team Account Khusus',
    desc: 'Tersedia tim khusus yang akan memantau setiap kiriman dari pelanggan korporat.',
    img: 'corporation/search-location.svg',
    bgColor: '',
  },
  {
    title: 'Live Tracking',
    desc: 'Kemudahan pelacakan kiriman melalui situs resmi dan aplikasi Lion Parcel.',
    img: 'corporation/tracking.svg',
    bgColor: '',
  },
  {
    title: 'Penarikan COD Realtime',
    desc: 'Tersedia dashboard untuk penarikan saldo COD secara langsung setelah paket diterima.',
    img: 'corporation/saldo.svg',
    bgColor: '',
  },
  {
    title: 'Staff Operasional Korporasi',
    desc: 'Adanya Staff Operasional di korporasi untuk memonitoring dan serah terima paket.',
    img: 'corporation/operational.svg',
    bgColor: '',
  },
  {
    title: 'Gratis Gudang Penyimpanan',
    desc: 'Tersedianya tempat menyimpan barang atau gudang secara gratis yang dikelola oleh LILO.',
    img: 'corporation/warehouse.svg',
    bgColor: '',
  },
  {
    title: 'Diskusikan Kebutuhan Anda Disini',
    desc: 'Email: sales@lionparcel.com',
    img: 'corporation/email.svg',
    bgColor: '',
  },
];

module.exports = { DATA };
