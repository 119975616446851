<template>
  <div class="corporateForm-section">
    <BreadCrumb
      class="top-left"
      :is-use-route-name="true"
    />
    <!-- Banner Header -->
    <div
      v-if="checkMobile"
      class="corporateForm-section__banner"
    >
      <img
        :src="`/images/corporation/form_corporation_banner_mobile.svg`"
        alt="corporateForm_banner_header"
      >
    </div>
    <!-- Banner Header -->
    <!-- Form -->
    <div class="corporateForm-section__form">
      <div class="corporateForm-section__form__coat">
        <h1 class="corporateForm-section__form__coat__title">
          Formulir Bisnis
        </h1>
        <div class="corporateForm-section__form__coat__wrapper">
          <Input
            v-model="corporation.company_name"
            name="company_name"
            placeholder="Masukkan nama perusahaan"
            :is-error="company_nameError"
            :error-message="company_nameErrorMsg"
            label="Nama Perusahaan"
            :is-required="true"
            @input="handleInput('company_name')"
          />
        </div>
        <div class="corporateForm-section__form__coat__wrapper">
          <Dropdown
            v-model="corporation.company_category"
            :options="CONSTANTS.CATEGORY"
            :value="corporation.company_category"
            name="company_category"
            placeholder="Pilih"
            :is-error="company_categoryError"
            :error-message="company_categoryErrorMsg"
            label="Kategori Perusahaan"
            :is-required="true"
            :is-search="true"
            @input="handleInput('company_category'), handleCategoryCorporate()"
          />
          <Input
            v-show="showOtherCategory"
            v-model="other_category"
            name="other_category"
            placeholder="Masukkan kategori perusahaan"
            :is-error="company_otherCategoryError"
            :error-message="company_otherCategoryErrorMsg"
            :is-required="true"
            class="other_category_class"
            @input="handleInput('other_category')"
          />
        </div>
        <div class="corporateForm-section__form__coat__wrapper">
          <Input
            v-model="corporation.pic_name"
            name="pic_name"
            placeholder="Masukkan nama penanggung jawab"
            :is-error="pic_nameError"
            :error-message="pic_nameErrorMsg"
            label="Nama Penanggung Jawab"
            :is-required="true"
            @input="handleInput('pic_name')"
          />
        </div>
        <div class="corporateForm-section__form__coat__wrapper">
          <Input
            v-model="corporation.job_position"
            name="job_position"
            :placeholder="placeholderLevel"
            :is-error="job_positionError"
            :error-message="job_positionErrorMsg"
            label="Jabatan Penanggung Jawab"
            :is-required="true"
            @input="handleInput('job_position')"
          />
        </div>
        <div class="corporateForm-section__form__coat__wrapper">
          <Input
            v-model="corporation.phone_number"
            name="phone_number"
            prefix="+62"
            placeholder="diawali angka 8"
            :is-error="phone_numberError"
            :error-message="phone_numberErrorMsg"
            label="Kontak Penanggung Jawab"
            :is-required="true"
            @input="handleInput('phone_number')"
          />
        </div>
        <div class="corporateForm-section__form__coat__wrapper">
          <Input
            v-model="corporation.billing_address"
            name="billing_address"
            placeholder="Nama Jalan, RT/RW, No Bangunan"
            :is-error="billing_addressError"
            :error-message="billing_addressErrorMsg"
            label="Alamat Penagihan"
            :is-required="true"
            @input="handleInput('billing_address')"
          />
        </div>
        <div class="corporateForm-section__form__coat__wrapper">
          <div class="corporateForm-section__form__coat__wrapper__cover">
            <Button
              text="Kirim"
              class="corporateForm-section__form__coat__wrapper__cover__button"
              @click="submit"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Form -->
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import mixinMobile from '@/misc/mixinMobile';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import Input from '../components/Input.vue';
import Dropdown from '../components/Dropdown.vue';
import Button from '@/components/new-button/Button.vue';
import CONSTANTS from '../app/constants/corporateCategory';
import usecase from '../app/usecase/formCorporate';

export default {
  name: 'CorporationForm',
  components: {
    BreadCrumb, Input, Dropdown, Button,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: '_corporationForm', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      CONSTANTS,
      other_category: '',
      showOtherCategory: false,
      corporation: {
        company_name: '',
        company_category: [],
        company_categoryVal: '',
        pic_name: '',
        job_position: '',
        phone_number: '',
        billing_address: '',
        category: 'CORPORATION',
      },
      company_nameError: false,
      company_nameErrorMsg: '',
      company_categoryError: false,
      company_categoryErrorMsg: '',
      company_otherCategoryError: false,
      company_otherCategoryErrorMsg: '',
      pic_nameError: false,
      pic_nameErrorMsg: '',
      job_positionError: false,
      job_positionErrorMsg: '',
      phone_numberError: false,
      phone_numberErrorMsg: '',
      billing_addressError: false,
      billing_addressErrorMsg: '',
    };
  },
  computed: {
    placeholderLevel() {
      return this.windowWidth <= 599 ? 'Masukkan jabatan' : 'Masukkan jabatan penanggung jawab';
    },
    checkMobile() {
      return this.windowWidth <= 599;
    },
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    handleCategoryCorporate() {
      if (this.corporation.company_category[0].value === 'Other') {
        this.showOtherCategory = true;
      } else {
        this.showOtherCategory = false;
        this.other_category = '';
      }
    },
    handleInput(name) {
      switch (name) {
        case 'company_name':
          this.company_nameError = false;
          break;
        case 'company_category':
          this.company_categoryError = false;
          break;
        case 'pic_name':
          this.pic_nameError = false;
          break;
        case 'job_position':
          this.job_positionError = false;
          break;
        case 'phone_number':
          this.phone_numberError = false;
          break;
        case 'billing_address':
          this.billing_addressError = false;
          break;
        case 'other_category':
          this.company_otherCategoryError = false;
          break;
        default: break;
      }
    },
    _otherCategoryCondition() {
      if (!this.other_category) {
        this.company_otherCategoryError = true;
        this.company_otherCategoryErrorMsg = 'Kategori perusahaan wajib diisi';
      }
    },
    _phoneElseCondition() {
      if (this.corporation.phone_number.charAt(0) !== '8') {
        this.phone_numberError = true;
        this.phone_numberErrorMsg = 'Format yang anda masukan salah';
      } else if (this.corporation.phone_number.length < 9 || this.corporation.phone_number.length > 12) {
        // inc prefix +62 (total min 12 char - max 15 char)
        this.phone_numberError = true;
        this.phone_numberErrorMsg = 'Panjang karakter antara 9-12 angka';
      }
    },
    check() {
      // company_name
      if (!this.corporation.company_name) {
        this.company_nameError = true;
        this.company_nameErrorMsg = 'Nama perusahaan wajib diisi';
      }
      // company_category
      try {
        this.corporation.company_categoryVal = this.corporation.company_category[0].value;
      } catch (err) {
        this.corporation.company_categoryVal = '';
      }
      if (!this.corporation.company_categoryVal) {
        this.company_categoryError = true;
        this.company_categoryErrorMsg = 'Kategori perusahaan wajib diisi';
      }
      // other_category
      if (this.showOtherCategory) {
        this._otherCategoryCondition();
      }
      // pic_name
      if (!this.corporation.pic_name) {
        this.pic_nameError = true;
        this.pic_nameErrorMsg = 'Nama penanggung jawab wajib diisi';
      }
      // job_position
      if (!this.corporation.job_position) {
        this.job_positionError = true;
        this.job_positionErrorMsg = 'Jabatan penanggung jawab wajib diisi';
      }
      // phone
      if (!this.corporation.phone_number) {
        this.phone_numberError = true;
        this.phone_numberErrorMsg = 'Kontak penanggung jawab wajib diisi';
      } else {
        this._phoneElseCondition();
      }
      // billing_address
      if (!this.corporation.billing_address) {
        this.billing_addressError = true;
        this.billing_addressErrorMsg = 'Alamat penagihan wajib diisi';
      }
      return !this.company_nameError
            && !this.company_categoryError
            && !this.pic_nameError
            && !this.job_positionError
            && !this.phone_numberError
            && !this.billing_addressError
            && !this.company_otherCategoryError;
    },
    async submit() {
      if (this.check()) {
        const corporation = {
          company_name: this.corporation.company_name,
          company_category: this.corporation.company_category[0].value,
          pic_name: this.corporation.pic_name,
          job_position: this.corporation.job_position,
          phone_number: `+62${this.corporation.phone_number}`,
          billing_address: this.corporation.billing_address,
          category: this.corporation.category,
        };
        if (this.showOtherCategory === true) {
          corporation.company_category = this.other_category;
        }
        const res = await usecase.create(corporation);
        if (!res.err) {
          // clear field
          this.corporation = '';
          alert('Data anda berhasil ditambahkan');
          this.$router.push('/');
        } else {
          alert(res.err.response.data.message.id);
        }
      }
    },
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
