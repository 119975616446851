<template>
  <div id="base-corporation">
    <header id="corporation-header">
      <bread-crumb
        id="breadcrumb-corporation"
        class="top-left"
      />
      <div class="corporation-motto">
        <h1 class="corporation-motto__title">
          Kembangkan Bisnis <br>Anda Bersama
        </h1>
        <h2 class="corporation-motto__info">
          Lion Parcel siap membantu Anda menjalankan bisnis untuk kebutuhan pengiriman barang ke seluruh wilayah
          Indonesia dengan harga yang <br><strong>kompetitif, aman dan terpercaya</strong>.
        </h2>
        <div class="corporation-motto__form">
          <the-button
            :text="titleForm"
            class="button-form"
            @click="$router.push('/korporasi/form')"
          />
        </div>
      </div>
      <image-lazy
        :src="getBannerCorporation"
        alt="Lion Parcel Korporasi.webp"
        @error="(event) => setErrorImage(event, 'banner')"
      />
    </header>
    <section id="corporation-profit">
      <profit-content :contents-list="PROFIT.DATA" />
    </section>
    <section class="corporation-registration">
      <profit-content
        color-title="red"
        :contents-list="REGISTRATION.DATA"
        title="Bagaimana proses pendaftaran Korporasi?"
      />
    </section>
    <section id="corporation-testimonial">
      <div id="corporation-testimonial__title">
        Klien Yang Sudah <br>Mempercayai Kami
      </div>
      <div id="corporation-testimonial__card">
        <card-testimonial
          v-for="(ts, idx) in TESTIMONIAL.DATA"
          :key="'testi-'+idx"
          :desc="ts.desc"
          :img="ts.img"
        />
      </div>
    </section>
    <footer id="corporation-footer">
      <div class="corporation-motto">
        <div id="corporation-footer__title">
          Mari Berbisnis
        </div>
        <div id="corporation-footer__info">
          Bersama Lion Parcel
        </div>
        <div id="corporation-footer__desc">
          Jika Anda adalah sebuah perusahaan yang membutuhkan jasa seperti kami, mari mulai berbisnis dengan <strong>Lion
            Parcel</strong>.
        </div>
        <div class="corporation-motto__form">
          <the-button
            text="Ajukan Kerjasama"
            @click="$router.push('/korporasi/form')"
          />
        </div>
        <div id="corporation-footer__contact">
          Diskusikan kebutuhan bisnis Anda di <br><a href="mailto:sales@lionparcel.com"> sales@lionparcel.com</a>
        </div>
      </div>
      <image-lazy
        :src="getBannerFooterCorporation"
        alt="Lion Parcel Korporasi.webp"
        @error="(event) => setErrorImage(event, 'footer')"
      />
    </footer>
    <div id="join-corporation">
      <router-link
        id="join-corporation__title"
        to="/korporasi/form"
      >
        {{ titleForm }}
      </router-link>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/new-button/Button';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import ProfitContent from '@/components/new-profit-and-registration/ProfitAndRegistrationContent';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import CardTestimonial from './components/CardTestimonial';
import PROFIT from './app/constants/profit';
import REGISTRATION from './app/constants/registration';
import TESTIMONIAL from './app/constants/testimonial';
import mixinMobile from '@/misc/mixinMobile';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  name: 'Corporation',
  components: {
    BreadCrumb,
    CardTestimonial,
    ProfitContent,
    TheButton,
    ImageLazy,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      titleForm: 'Formulir Bisnis',
      PROFIT,
      REGISTRATION,
      TESTIMONIAL,
      searchMetaInfo: '_korporasi',
    };
  },
  computed: {
    getBannerCorporation() {
      return this.isMobileSize
        ? '/images/corporation/header-mobile.webp'
        : '/images/corporation/header.webp';
    },
    getBannerFooterCorporation() {
      return this.isMobileSize
        ? '/images/corporation/footer-mobile.webp'
        : '/images/corporation/footer.webp';
    },
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    setErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
