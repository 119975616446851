const DATA = [
  {
    img: '/corporation/berrybenka.png',
    desc: '“Kita puas dengan performanya, cepat sampai, fast respon kalau ada masalah, dan juga harganya murah, paling jagoan sih kalo soal harga murah.”',
  },
  {
    img: '/corporation/riamiranda.png',
    desc: '“Lion Parcel memenuhi dan mengakomodasi kebutuhan dari Ria Miranda dan tentunya kami sangat merekomendasi untuk yang punya bisnis untuk menggunakan Lion Parcel.”',
  },
  {
    img: '/corporation/hijup.png',
    desc: '“Lion Parcel sistemnya mudah dan tentunya harganya lebih reasonable, tidak pernah ada keluhan terhadap pengiriman Lion Parcel. Saya sangat merekomendasikan Lion Parcel untuk pengiriman pesanan kamu.”',
  },
  {
    img: '/corporation/bukalapak.png',
    desc: '“Lion Parcel memberikan  pengalaman yang luar biasa dalam penanganannya, sehingga paket yang dibeli pelanggan bisa datang lebih cepat, dan juga tepat waktu.”',
  },
];

module.exports = { DATA };
