<template>
  <div class="do-flex-bread">
    <img
      :src="`/images/new-breadcrumb-home.svg`"
      alt="icon-home"
      style="cursor: pointer"
      @click="$router.push('/')"
    />
    <div
      v-for="(p, i) in listPath"
      :key="'breadCrumb-' + i"
      style="display: inline-flex;"
    >
      <span class="slash ml-2"> /</span>
      <span
        v-if="i + 1 !== listPath.length"
        class="title-breadcrumb"
        style="display: inline-block;"
      >
        <a href="javascript:void(0)" @click="push(p.path)">
          {{ p.name }}
        </a></span
      >
      <span v-else class="title-breadcrumb">
        {{ p.name.replace(/-/g, ' ') }}</span
      >
    </div>
    <span v-if="title && title !== ''" class="slash ml-3"> /</span>
    <span
      v-if="title && title !== ''"
      :id="customTitleID"
      class="title-breadcrumb"
    >
      {{ title }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: {
    title: {
      type: String,
      required: false,
    },
    customTitleID: {
      type: String,
    },
    isUseRouteName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    listPath() {
      const data = [];
      if (this.$route.matched.length > 1) {
        this.$route.matched.forEach(val => {
          if (val.name) {
            const paramName = this._getIfPathValueIsMatch(val, /:\w*/g);
            if (paramName.length > 0) {
              data.push({
                path: val.path,
                name: this._checkIsRouteName(paramName, val),
              });
            } else {
              data.push({
                path: val.path,
                name: val.name,
              });
            }
          }
        });
      } else if (this.$route.name) {
        data.push({
          path: this.$route.path,
          name: this.$route.name,
        });
      }
      return data;
    },
  },
  methods: {
    push(path) {
      const newPath = path
        .replace(/:\w*/g, val => {
          const param = val.slice(1, val.length);
          return this.$route.params[param];
        })
        .split('/')
        .filter(v => v)
        .join('/');
      this.$router.push(`/${newPath}`);
    },
    _checkIsRouteName(paramName, val) {
      const routeParam = paramName[paramName.length - 1].split(':')[1];
      const name = this.$route.params[routeParam];

      return this.isUseRouteName ? val.name : name;
    },
    _getIfPathValueIsMatch(val, regex) {
      return val.path.match(regex) || [];
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/css/style.scss';
@import '../../assets/css/spacing.scss';

.do-flex-bread {
  display: flex;
  flex-direction: row;
  padding-left: 4%;
  background-color: transparent;
  gap: 8px;
  @include for-size(mobile) {
    display: none;
  }
  @include for-size(xmobile) {
    display: none;
  }
}

.slash {
  color: #a7a8aa;
  margin: 0;
  padding-right: 6px;
}

.title-breadcrumb,
a {
  font-style: normal;
  font-weight: 600;
  font-size: 12pt;
  text-transform: capitalize;
  line-height: 18pt;
}

a {
  color: #a7a8aa;
}
</style>
