const DATA = [
  {
    title: 'Isi Formulir / Hubungi Kontak Bisnis',
    desc: 'Mengisi form kerjasama bisnis sebagai perusahaan atau bisa langsung menghubungi kontak bisnis yang tertera.',
    img: 'corporation/ics_f_file.svg',
    isFlexDirectionColumn: true,
  },
  {
    title: 'Verifikasi Kerjasama',
    desc: 'Tim Sales Lion Parcel akan menghubungi Anda untuk menindaklanjuti\n'
            + 'permintaan Anda.',
    img: 'corporation/ics_f_clipboard.svg',
    isFlexDirectionColumn: true,
  },
  {
    title: 'Konfirmasi Kerjasama',
    desc: 'Anda akan melengkapi dokumen pendukung untuk badan usaha atau untuk perorangan cukup KTP dan NPWP. ',
    img: 'corporation/ics_f_task_check.svg',
    isFlexDirectionColumn: true,
  },
];

module.exports = { DATA };
